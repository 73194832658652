<template>
  <div class="white_bg_area">
    <label>SNS</label>
    <!-- <div class="flex" style="-webkit-align-items: center; align-items: center;">
          <div class="mr_10"> 
            https://X.com/ 
          </div>
          <div>
            <input type="text" name="twitter" :value="twitter" placeholder="XIDを入力"
                                        @input="$emit('update:twitter', $event.target.value)">
          </div>
    </div> -->
    <div class="input_twitter"><input type="text" name="twitter" :value="twitter" placeholder="TwitterIDを入力"
                                      @input="$emit('update:twitter', $event.target.value)"></div>
    <div class="input_facebook"><input type="text" name="facebook" :value="facebook" placeholder="FacebookIDを入力"
                                       @input="$emit('update:facebook', $event.target.value)"></div>
    <div class="input_instagram"><input type="text" name="instagram" :value="instagram" placeholder="InstagramIDを入力"
                                        @input="$emit('update:instagram', $event.target.value)"></div>
  </div><!-- .white_bg_area -->
</template>

<script>
export default {
  name: 'SignUpFormSNS',
  props: {
    twitter: String,
    facebook: String,
    instagram: String,
  },
};
</script>
