<template>
  <div class="white_bg_area">

    <div class="form-group form-group--nick" :class="{ 'form-group--error': $v.nick.$error }">
      <label>ニックネーム</label>

      <div class="error" v-if="$v.nick.$dirty && !$v.nick.required">入力してください</div>
      <div class="error" v-if="!$v.nick.maxLength">{{ $v.nick.$params.maxLength.max }}文字以内で入力してください</div>
      <input type="text" :placeholder="`${$v.nick.$params.maxLength.max}文字以内で入力してください`"
             :value="nick"
             @change="setValue('nick', $event.target.value)"/>
    </div>

    <div :class="{ 'form-group--error': $v.last_name.$error || $v.first_name.$error }">
      <label>お名前</label>
      <div class="error" v-if="($v.last_name.$dirty && $v.first_name.$dirty) && (!$v.last_name.required || !$v.first_name.required)">入力してください</div>
      <div class="form_2col">
        <input type="text" :value="last_name" placeholder="姓" @change="setValue('last_name', $event.target.value)">
        <input type="text" :value="first_name" placeholder="名" @change="setValue('first_name', $event.target.value)">
      </div>
    </div>

    <div :class="{ 'form-group--error': $v.last_kana.$error || $v.first_kana.$error }">
      <label>フリガナ</label>
      <div class="error" v-if="($v.last_kana.$dirty && $v.first_kana.$dirty) && (!$v.last_kana.required || !$v.first_kana.required)">入力してください</div>
      <div class="form_2col">
        <input type="text" :value="last_kana" placeholder="セイ" @change="setValue('last_kana', $event.target.value)">
        <input type="text" :value="first_kana" placeholder="メイ" @change="setValue('first_kana', $event.target.value)">
      </div>
    </div>

    <div :class="{ 'form-group--error': $v.birthday.$error }">
      <label>生年月日</label>
      <div class="error" v-if="$v.birthday.$dirty && !$v.birthday.required">入力してください</div>
      <div class="select-ymd">
        <div class="select-wrap">
          <select name="year" v-model="birthday_year">
            <option disabled value="">-</option>
            <option v-for="year in birth_years"
                    :value="year"
                    :key="`year_${year}`"
                    @input="setValue('birthday', birthday)"
            >{{ year }}年</option>
          </select>
        </div>
        <div class="select-wrap">
          <select name="month" v-model="birthday_month">
            <option disabled value="">-</option>
            <option v-for="month in Array.from(Array(12).keys()).map(i => ('0' + ++i).slice(-2))"
                    :value="month"
                    :key="`month_${month}`"
                    @input="setValue('birthday', birthday)"
            >{{ month.replace(/^0/, '') }}月</option>
          </select>
        </div>
        <div class="select-wrap">
          <select name="date" v-model="birthday_day">
            <option disabled value="">-</option>
            <option v-for="day in Array.from(Array(31).keys()).map(i => ('0' + ++i).slice(-2))"
                    :value="day"
                    :key="`day_${day}`"
                    @input="setValue('birthday', birthday)"
            >{{ day.replace(/^0/, '') }}日</option>
          </select>

  <!--        <input type="date" :value="birthday" placeholder="生年月日を選択してください"-->
  <!--               @input="$emit('update:birthday', $event.target.value)">-->
        </div><!-- .select-wrap -->
      </div>
    </div>
    
        <template v-if="mode==='create'">
          <div :class="{ 'form-group--error': $v.email.$error }">
            <label>メールアドレス</label>
            <div class="error" v-if="$v.email.$dirty && !$v.email.required">入力してください</div>
            <div class="error" v-if="$v.email.$dirty && !$v.email.maxLength">200文字以内で入力してください</div>
            <div class="error" v-if="$v.email.$dirty && !$v.email.email">正しいメールアドレスを入力してください</div>
            <div class="error" v-if="$v.email.$dirty && !$v.email.unique">すでに使用されています</div>
            <input type="email" :value="email" placeholder="メールアドレスを入力してください"
                   @change="setValue('email', $event.target.value)">
          </div>
        </template>

    <div :class="{ 'form-group--error': $v.password.$error }">
      <div style="display: flex;">
        <label>パスワード</label>
        <div class="visibility">
          <button type="button" @click="togglePasswordVisibility" style="border: none;">
            <i :class="{'fa': true, 'fa-eye': passwordFieldType === 'password', 'fa-eye-slash': passwordFieldType !== 'password'}"></i>
          </button>
        </div>
      </div>
      <div class="error" v-if="$v.password.$dirty && !$v.password.required">入力してください</div>
      <div class="error" v-if="$v.password.$dirty && !$v.password.minLength">4文字以上で入力してください</div>
      <div class="error" v-if="$v.password.$dirty && !$v.password.maxLength">200文字以内で入力してください</div>
      <input :type="passwordFieldType" v-model="password" :value="password" placeholder="パスワードを入力してください"
              @change="setValue('password', $event.target.value)">
    </div>

    <div :class="{ 'form-group--error': $v.password_confirm.$error }">
      <div style="display: flex;">
        <label>パスワード確認</label>
        <div class="visibility">
          <button type="button" @click="toggleConfirmPasswordVisibility" style="border: none;">
            <i :class="{'fa': true, 'fa-eye': passwordConfirmFieldType === 'password', 'fa-eye-slash': passwordConfirmFieldType !== 'password'}"></i>
          </button>
        </div>
      </div>
      <div class="error" v-if="$v.password_confirm.$dirty && !$v.password_confirm.sameAsPassword">パスワードと同じ文字列を入力してください</div>
      <input :type="passwordConfirmFieldType" v-model="password_confirm" :value="password_confirm" placeholder="パスワードを入力してください"
              @change="setValue('password_confirm', $event.target.value)">
    </div>


    <div>
      <label>ひと言</label>
      <textarea :value="comment" rows="5" cols="" placeholder="メッセージを入力してください"
                @change="setValue('comment', $event.target.value)"></textarea>
    </div>

  </div><!-- .white_bg_area -->
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, minLength, maxLength, sameAs, email} from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
  name: 'SignUpFormProfile',
  props: {
    nick: String,
    last_name: String,
    first_name: String,
    last_kana: String,
    first_kana: String,
    // birthday: String,
    email: String,
    password: String,
    password_confirm: String,
    comment: String,
    mode: {
      type: String,
      default: 'create',
      validator: function (value) {
        return ['create', 'edit'].indexOf(value) !== -1;
      },
    }
  },
  data: function () {
    return {
      birthday_year: '',
      birthday_month: '',
      birthday_day: '',
      birth_years: Array.from(Array(100).keys()).map(i => {
        return Number(moment().format('YYYY')) - i;
      }),
      passwordFieldType: 'password',
      passwordConfirmFieldType: 'password',
    };
  },
  computed: {
    birthday: {
      get: function () {
        if (!this.birthday_year || !this.birthday_month || !this.birthday_day) {
          return null;
        }
        const birthday = `${this.birthday_year}-${this.birthday_month}-${this.birthday_day}`;
        this.$emit('update:birthday', birthday)
        return birthday;
      },
      set: function (newValue) {
        const dates = newValue.split('-')
        this.birthday_year = dates[0];
        this.birthday_month = dates[1];
        this.birthday_day = dates[2];
      },
    }
  },
  mixins: [validationMixin],
  validations() {
    if (this.mode === 'create') {
      return {
        nick: {
          required,
          maxLength: maxLength(10),
        },
        first_name: {
          required,
          maxLength: maxLength(200),
        },
        last_name: {
          required,
          maxLength: maxLength(200),
        },
        first_kana: {
          required,
          maxLength: maxLength(200),
        },
        last_kana: {
          required,
          maxLength: maxLength(200),
        },
        birthday: {
          required,
        },
        email: {
          required,
          maxLength: maxLength(200),
          email,
          async unique (val) {
            if (val.trim().length === 0) return true
            let isUnique = true
            const response = await this.$http.post('users/count', {email : this.email});
            isUnique = Number(response.data) === 0;
            console.log(isUnique);
            return Boolean(isUnique)
          }
        },
        password: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(200),
        },
        password_confirm: {
          required,
          sameAsPassword: sameAs('password')
        },
      }
    } else if (this.mode === 'edit') {
      return {
        nick: {
          required,
          maxLength: maxLength(10),
        },
        first_name: {
          required,
          maxLength: maxLength(200),
        },
        last_name: {
          required,
          maxLength: maxLength(200),
        },
        first_kana: {
          required,
          maxLength: maxLength(200),
        },
        last_kana: {
          required,
          maxLength: maxLength(200),
        },
        birthday: {
          required,
        },
        password: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(200),
        },
        password_confirm: {
          required,
          sameAsPassword: sameAs('password')
        },
      }
    }
  },
  created () {
    if (this.$attrs.birthday) {
      const dates = this.$attrs.birthday.split('-')
      this.birthday_year = dates[0];
      this.birthday_month = dates[1];
      this.birthday_day = dates[2];
    }
  },
  methods: {
    setValue (property, value) {
      // console.log(property, $event);
      this.$emit(`update:${property}`, value);
      this.$v[property].$touch();
      this.$emit('update:error', this.$v.$anyError);
    },

    // 親から呼ばれる
    invalid () {
      this.$v.$touch();
      return this.$v.$invalid;
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    toggleConfirmPasswordVisibility() {
      this.passwordConfirmFieldType = this.passwordConfirmFieldType === 'password' ? 'text' : 'password';
    },
  }
};
</script>

<style lang="scss" scoped>
#wrap {
  .form-group--error {
    input, select, textarea {
      border: 1px solid #ffd3ce;
      background-color: #ffd3ce;
    }
  }
  .error {
    font-size: 10rem;
    color: #ff7666;
    margin: 5px 0 -5px;
  }
}
</style>
