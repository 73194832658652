<template>

  <section class="contents">

    <div class="contentInner">
      <div class="profile_photo" v-if="mode !== 'edit'">
        <img v-if="image_b64" :src="image_b64" alt="プロフィール写真">
        <img v-if="!image_b64" src="@/assets/img/signup/photo-camera.png" alt="プロフィール写真">
      </div>

      <div class="white_bg_area">

        <div class="profile_item">
          <div class="item_label">ニックネーム</div>
          <div>{{ nick }}</div>
        </div>

        <div class="profile_item">
          <div class="item_label">お名前</div>
          <div>{{ last_name }} {{ first_name }}</div>
        </div>

        <div class="profile_item">
          <div class="item_label">フリガナ</div>
          <div>{{ last_kana }} {{ first_kana }}</div>
        </div>

        <div class="profile_item">
          <div class="item_label">生年月日</div>
          <div>{{ birthday }}</div>
        </div>

        <div class="profile_item">
          <div class="item_label">メールアドレス</div>
          <div>{{ email }}</div>
        </div>

        <div class="profile_item">
          <div class="item_label">パスワード</div>
          <div>{{ password }}</div>
        </div>

        <div class="profile_item">
          <div class="item_label">ひと言</div>
          <div>{{ comment }}</div>
        </div>

        <div class="profile_item">
          <div class="item_label">SNS</div>
          <div>
            <img v-if="twitter" src="@/assets/img/logo_twitter.png" alt="Twitterロゴ">
            <img v-if="facebook" src="@/assets/img/logo_facebook.png" alt="Facebookロゴ">
            <img v-if="instagram" src="@/assets/img/logo_instagram.png" alt="Instagramロゴ">
          </div>
        </div>

        <div class="bt_form mb_20"><button type="button" value="send" @click="send">送信</button></div>

      </div><!-- .white_bg_area -->

    </div><!--.contentInner-->
  </section>

</template>

<script>
export default {
  name: 'SignupConfirm',
  props: {
    nick: String,
    last_name: String,
    first_name: String,
    last_kana: String,
    first_kana: String,
    birthday: String,
    email: String,
    password: String,
    password_confirm: String,
    comment: String,
    twitter: String,
    facebook: String,
    instagram: String,
    image_b64: String,
    mode: String,
  },
  data: function () {
    return {
    }
  },
  methods: {
    send () {
      this.$emit('send');
      // this.$modal.hide('send');
    }
  }
}
</script>
